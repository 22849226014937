.privacy-page-layout {
    max-width: 800px;
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    font-size: 16px;
}

.privacy-link-layout {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.privacy-link{
    color:#adb5bd;
    padding: 4px 8px;
}

.privacy-link:hover {
    text-decoration: none;
}

.privacy-heading {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.privacy-title-border {
    border-width: 1px 0 1px 0;
    border-color: #0d6efd;
    border-style: solid;
    padding: 8px 0;
    text-align: center;
    margin-bottom: 12px;
}

.privacy-title {
    font-size: 24px;
    font-weight: bold;
    color:#0d6efd;
    margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
    .privacy-link-layout{
        display: none;
    }
}