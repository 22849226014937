.deliverybar {
  background-color: #191c27;
  font-size: 12px;
  height: 40px;
  color: white;
}

.deliverybar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.deliverybar .timeslot {
  font-weight: bold;
}

.deliverybar .slotlist {
  font-weight: bold;
  padding: 0;
  position: absolute;
  top: 29px;
  left: 0;
  width: max-content;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
  padding: 16px;
  z-index: 11;
}

.slotlist .header {
  color: #464646;
  font-weight: 500;
  font-size: 14px;
}

.slotlist .slotbtn {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #a3a3a3;
  font-size: 14px;
  outline: none;
  border: none;
  background-color: white;
}

.slotlist .slotbtn:hover {
  color: black;
}

.slotlist .timegrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;
}

.timegrid .griditem {
  color: #747474;
  font-size: 14px;
  font-weight: 400;
  padding: 7px 12px;
}

.slotlist .active {
  color: black;
}

.deliverybar .divider {
  height: 32px;
  border: 1px solid #747474;
}

.deliverybar .showmorebutton {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0;
  background-color: #191c27;
  color: white;
  outline: none;
  border: none;
}

.transparent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
}

@media only screen and (max-width: 768px) {
  .deliverybar {
    display: none;
  }
}
