.search-wrap {
  flex-grow: 1;
  background: #f2f2f2;
  border-radius: 8px;
  margin: 0px 10px;
  display: flex;
  max-width: 600px;
  z-index: 10;
}
.search-wrap-svg {
  color: #00983d;
  font-size: 20px;
}
.search-wrap-icon {
  padding: 10px 15px;
  user-select: none;
}
.search-wrap-input {
  background-color: transparent;
  border: 0px;
  flex-grow: 1;
  font-size: 14px;
  padding: 5px 20px;
  outline: none;
}
.search-wrap-input:focus {
  outline: none;
}

@media only screen and (max-width: 768px) {
  .search-wrap {
    order: 3;
  }
}

@media only screen and (max-width: 500px) {
  .search-wrap {
    margin-bottom: 6px;
  }
}
